export default {
  proxy_path: "https://ibhv77u1bi.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://7q6qmsyc37.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyCvHe11XoXXIdcqwKIRI9kivWPjAvNdaAQ",
  main_company_term_id: "6",
  crm_base_url: "testing.newmont.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.testing.newmont.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.testing.newmont.forwoodsafety.com",
    APP_CLIENT_ID: "2lmj324jlk8dpuqs8te99r0733",
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://u8dosodk89.execute-api.us-west-2.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.testing.newmont.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
